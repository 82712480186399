<template>
  <CyDetails
    :save-btn-text="$t('createWatchRule')"
    :can-cancel="true"
    :on-cancel="onCancel"
    :can-save="canSave"
    :on-save="createWatchRule"
    :saving="saving"
    :loading="false">
    <template #details_formFullWidth>
      <v-container
        v-if="!selectedWatchRuleType"
        class="px-0 pt-6"
        fluid>
        <v-row
          align="start"
          justify="start"
          no-gutters>
          <v-col cols="3">
            <h3 v-text="$t('sections.type.title')"/>
          </v-col>
          <v-col cols="6">
            <ul class="watch-rule-types">
              <li
                v-for="watchRuleType in $static.watchRuleTypes"
                :key="watchRuleType.key"
                :aria-label="watchRuleType.label"
                class="watch-rule-types__item mb-2"
                @click="selectedWatchRuleType = watchRuleType">
                <CyAvatar
                  :item="{ icon: watchRuleType.icon, color: watchRuleType.color }"
                  class="mr-4"/>
                <div>
                  <div
                    class="h6"
                    v-text="watchRuleType.name"/>
                  <div v-text="watchRuleType.description"/>
                </div>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
      <v-container
        v-else
        class="px-0 pt-6"
        fluid>
        <v-row
          align="start"
          justify="start"
          no-gutters>
          <v-col cols="3">
            <h3 v-text="$t('sections.general.title')"/>
          </v-col>
          <v-col cols="6">
            <label
              class="watch-rule-type__label"
              v-text="$t('watchRuleType')"/>
            <ul class="watch-rule-types--selected">
              <li
                :aria-label="selectedWatchRuleType.label"
                class="watch-rule-types__item my-1">
                <CyAvatar
                  :item="{ icon: selectedWatchRuleType.icon, color: selectedWatchRuleType.color }"
                  class="mr-4"/>
                <div>
                  <div
                    class="h6"
                    v-text="selectedWatchRuleType.name"/>
                  <div v-text="selectedWatchRuleType.description"/>
                </div>
              </li>
            </ul>
            <button
              class="change-type__btn"
              @click="changeType"
              v-text="$t('changeType')"/>
            <v-text-field
              v-model="$v.watchRule.name.$model"
              :label="$t('fields.ruleName.label')"
              :hint="$t('fields.ruleName.hint')"
              :error-messages="nameErrors"
              persistent-hint
              class="mb-6 required-field"
              @blur="$v.watchRule.name.$touch()"/>
            <v-select
              v-model="$v.watchRule.organization.$model"
              :items="organizations"
              item-text="name"
              item-value="canonical"
              :error-messages="organizationErrors"
              :label="$t('fields.organization.label')"
              :hint="$t('fields.organization.hint')"
              persistent-hint
              class="mb-6 required-field"
              @blur="$v.watchRule.organization.$touch()">
              <template #selection="{ item }">
                <CyAvatar
                  class="mr-2 my-2"
                  :item="item"
                  sm/>
                {{ item.name }}
              </template>
              <template #item="{ item, attrs, on }">
                <v-list-item
                  role="option"
                  class="px-1"
                  v-bind="attrs"
                  v-on="on">
                  <v-list-item-avatar class="mr-2">
                    <CyAvatar
                      :item="item"
                      sm/>
                  </v-list-item-avatar>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
            <v-select
              v-if="selectedWatchRuleType.key === 'projectActivity'"
              v-model="$v.watchRule.project.$model"
              :items="projects"
              item-text="name"
              item-value="canonical"
              :error-messages="projectErrors"
              :label="$t('fields.project.label')"
              :hint="$t('fields.project.hint')"
              persistent-hint
              class="mb-6 required-field"
              @blur="$v.watchRule.project.$touch()">
              <template #selection="{ item }">
                <v-icon class="mr-2 my-2">
                  folder_open
                </v-icon>
                {{ item.name }}
              </template>
              <template #item="{ item, attrs, on }">
                <v-list-item
                  role="option"
                  class="px-3"
                  v-bind="attrs"
                  v-on="on">
                  <v-list-item-icon class="mr-3">
                    <v-icon>folder_open</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
            <v-switch
              v-model="$v.watchRule.muted.$model"
              color="secondary"
              :label="$t('fields.mute.label')"
              :hint="$t('fields.mute.hint')"
              persistent-hint/>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </CyDetails>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CyDetails from '@/components/details.vue'
import { constructBreadcrumb } from '@/utils/helpers'
import { required, requiredIf } from 'vuelidate/lib/validators'

const EMPTY_WATCH_RULE = {
  name: '',
  organization: '',
  project: '',
  muted: false,
}

export default {
  name: 'CyPageUserWatchRule',
  components: {
    CyDetails,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('title'), [
      {
        label: this.$t('routes.profile'),
        name: 'profile',
      },
    ])
  },
  header () {
    return {
      title: this.$t('title'),
      description: {
        text: this.$t('description'),
      },
    }
  },
  validations () {
    const { selectedWatchRuleType } = this
    return {
      watchRule: {
        name: { required },
        organization: { required },
        project: {
          required: requiredIf(() => selectedWatchRuleType?.key === 'projectActivity'),
        },
        muted: {},
      },
    }
  },
  data: () => ({
    selectedWatchRuleType: null,
    watchRule: _.cloneDeep(EMPTY_WATCH_RULE),
    saving: false,
  }),
  computed: {
    ...mapState(['organizations']),
    ...mapState('organization', {
      projects: (state) => state.available.projects,
    }),
    $static () {
      return {
        watchRuleTypes: [
          {
            label: 'Project activity',
            name: this.$t('watchRuleTypes.project.name'),
            description: this.$t('watchRuleTypes.project.description'),
            icon: 'commit',
            color: 'prod',
            key: 'projectActivity',
          },
          {
            label: 'Event tracking',
            name: this.$t('watchRuleTypes.event.name'),
            description: this.$t('watchRuleTypes.event.description'),
            icon: 'podcasts',
            color: 'staging',
            key: 'eventTracking',
          },
        ],
      }
    },
    nameErrors () {
      const { $dirty, required } = this.$v.watchRule.name
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    organizationErrors () {
      const { $dirty, required } = this.$v.watchRule.organization
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    projectErrors () {
      if (this.selectedWatchRuleType.key !== 'projectActivity') return []
      const { $dirty, required } = this.$v.watchRule.project
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    canSave () {
      return !this.$v.$invalid
    },
  },
  created () {
    this.FETCH_AVAILABLE({ keyPath: 'projects' })
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('notifications', [
      'CREATE_WATCH_RULE',
    ]),
    async createWatchRule () {
      this.saving = true
      const { name, muted } = this.watchRule
      const watchRule = {
        name,
        canonical: this.$getSlug(name),
        organization_canonical: this.watchRule.organization,
        ...(this.selectedWatchRuleType.key === 'projectActivity'
          ? {
              project_canonical: this.watchRule.project,
            }
          : {}),
        muted,
        filters: [{ operation: '*', entity: '*' }],
      }
      this.CREATE_WATCH_RULE({ watchRule, $router: this.$router })
      this.saving = false
    },
    changeType () {
      this.selectedWatchRuleType = null
      this.clearForm()
    },
    clearForm () {
      this.watchRule = _.cloneDeep(EMPTY_WATCH_RULE)
      this.$v.$reset()
    },
    onCancel () {
      this.$router.push({ name: 'userWatchRules' })
    },
  },
  i18n: {
    messages: {
      en: {
        title: 'Create watch rule',
        description: 'Watch rules help you stay in the loop by sending notifications whenever changes are made to a project or a specific event occurs.',
        changeType: 'Change rule type',
        createWatchRule: 'Create watch rule',
        fields: {
          ruleName: {
            label: 'Rule name',
            hint: 'Enter a descriptive name for the watch rule',
          },
          organization: {
            label: '@:Organization',
            hint: 'Select the organization for this rule. A rule is always scoped to a single organization.',
          },
          project: {
            label: '@:Project',
            hint: 'Select the project to watch',
          },
          mute: {
            label: 'Mute',
            hint: 'Prevent the watch rule from emitting notifications',
          },
        },
        sections: {
          type: {
            title: 'Select watch rule type',
          },
          general: {
            title: '@:general',
          },
        },
        watchRuleTypes: {
          project: {
            name: 'Project activity',
            description: 'Get notified when changes are made within a project.',
          },
          event: {
            name: 'Event tracking',
            description: 'Get notified for specific events within an organization.',
          },
        },
        watchRuleType: 'Watch rule type',
      },
      es: {
        title: 'Crear regla de monitoreo',
        description: 'Las reglas de monitoreo te ayudan a mantenerte informado enviando notificaciones cada vez que se realizan cambios en un proyecto o ocurre un evento específico.',
        changeType: 'Cambiar tipo de regla',
        createWatchRule: 'Crear regla de monitoreo',
        fields: {
          ruleName: {
            label: 'Nombre de la regla',
            hint: 'Ingresa un nombre descriptivo para la regla de monitoreo',
          },
          organization: {
            label: '@:Organization',
            hint: 'Selecciona la organización para esta regla. Una regla siempre está limitada a una sola organización.',
          },
          project: {
            label: '@:Project',
            hint: 'Selecciona el proyecto a monitorear',
          },
          mute: {
            label: 'Silenciar',
            hint: 'Evita que la regla de monitoreo emita notificaciones',
          },
        },
        sections: {
          type: {
            title: 'Selecciona el tipo de regla de monitoreo',
          },
          general: {
            title: '@:general',
          },
        },
        watchRuleTypes: {
          project: {
            name: 'Actividad del proyecto',
            description: 'Recibe notificaciones cuando se realizan cambios dentro de un proyecto.',
          },
          event: {
            name: 'Seguimiento de eventos',
            description: 'Recibe notificaciones para eventos específicos dentro de una organización.',
          },
        },
        watchRuleType: 'Tipo de regla de monitoreo',
      },
      fr: {
        title: 'Créer une règle de notification',
        description: `Les règles de notification vous aident à rester informé en vous envoyant des notifications chaque fois qu'un changement est apporté à un projet ou qu'un événement spécifique se produit.`,
        changeType: 'Changer le type de règle',
        createWatchRule: 'Créer une règle de notification',
        fields: {
          ruleName: {
            label: 'Nom de la règle',
            hint: 'Entrez un nom descriptif pour la règle de notification',
          },
          organization: {
            label: '@:Organization',
            hint: `Sélectionnez l'organisation pour cette règle. Une règle est toujours limitée à une seule organisation.`,
          },
          project: {
            label: '@:Project',
            hint: `Sélectionnez le projet à surveiller`,
          },
          mute: {
            label: 'Désactivée',
            hint: `Empêchez la règle de notification d'émettre des notifications`,
          },
        },
        sections: {
          type: {
            title: 'Sélectionner le type de règle de notification',
          },
          general: {
            title: '@:general',
          },
        },
        watchRuleTypes: {
          project: {
            name: 'Activité du projet',
            description: `Recevez des notifications lorsque des changements sont apportés à un projet.`,
          },
          event: {
            name: 'Suivi des événements',
            description: `Recevez des notifications pour des événements spécifiques au sein d'une organisation.`,
          },
        },
        watchRuleType: 'Type de règle de notification',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.change-type__btn {
  color: cy-get-color("secondary");
  font-weight: $font-weight-bolder;
  cursor: pointer;
}

.watch-rule-type__label {
  color: cy-get-color("grey", "dark-2");
}

.watch-rule-types {
  list-style: none;

  &__item {
    display: flex;
    align-items: center;
    padding: 16px;
    padding-left: 8px;
    border: 1px solid cy-get-color("primary", "light-4");
    border-radius: 4px;
    background-color: cy-get-color("white");
    color: cy-get-color("primary");
    cursor: pointer;

    &:hover {
      background: cy-get-color("grey", "light-4");
    }
  }

  &--selected {
    padding-left: 0;
  }
}
</style>
